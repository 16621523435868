// src/templates/pages/TeamListing.js

import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import parse from 'html-react-parser'
import cx from 'classnames'

function TeamListing({ data }) {
  const pageData = data.wpPage.template.teamPage

  const [selectedFilter, setSelectedFilter] = useState('all')

  const toggleFilters = function (event) {
    setSelectedFilter(event.target.value)
  }

  return (
    <Layout>
      <Seo post={data.wpPage} />

      <div className="bio-inner space-y-responsive-xl-48">
        <section className="page-intro relative">
          <div className="container">
            <div className="mb-responsive-xl-32 border-b border-granite-200 py-responsive-xl-64 text-center">
              {data.wpPage.title && (
                <h1 className="headline-xxl mb-responsive-xl-24">
                  {parse(data.wpPage.title)}
                </h1>
              )}

              {pageData.description && (
                <div className="container-inner">
                  <p className="text-xl">{parse(pageData.description)}</p>
                </div>
              )}
            </div>
          </div>
        </section>

        {pageData.teamGroupFilters && (
          <section className="team-filter flex items-center justify-center">
            <p className="mr-responsive-xl-16 font-medium text-xs uppercase tracking-wider">
              Filter by:
            </p>

            {/* buttons for large screens */}
            <ul className="hidden items-center md:inline-flex">
              <li>
                <button
                  value={'all'}
                  onClick={toggleFilters}
                  aria-selected={selectedFilter === 'all'}
                  className={cx(
                    'rounded-sm px-responsive-xl-16 py-responsive-xl-12 font-medium text-nav tracking-wider',
                    {
                      'border border-boynton-200 bg-boynton-100':
                        selectedFilter === 'all',
                    }
                  )}
                >
                  All
                </button>
              </li>

              {pageData.teamGroupFilters.map((node, index) => {
                return (
                  <li key={index}>
                    <button
                      value={node.slug}
                      onClick={toggleFilters}
                      aria-selected={selectedFilter === node.slug}
                      className={cx(
                        'rounded-sm  px-responsive-xl-16 py-responsive-xl-12 font-medium text-nav tracking-wider',
                        {
                          'border border-boynton-200 bg-boynton-100':
                            selectedFilter === node.slug,
                        }
                      )}
                    >
                      {node.name}
                    </button>
                  </li>
                )
              })}
            </ul>

            {/* control for small screens */}
            <select
              value={selectedFilter}
              onChange={toggleFilters}
              className="rounded-sm border border-boynton-200 bg-boynton-100 px-responsive-xl-16 py-responsive-xl-12 md:hidden"
            >
              <option value={'all'}>All</option>
              {pageData.teamGroupFilters.map((node, index) => {
                return (
                  <option key={index} value={node.slug}>
                    {node.name}
                  </option>
                )
              })}
            </select>
          </section>
        )}

        <section className="team-list">
          <div className="container">
            <ul className="grid grid-cols-1 gap-responsive-xl-32 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pageData.teamMembers.map((node, index) => {
                const imageSrc = node.bio.image
                  ? getImage(node.bio.image.localFile)
                  : null

                return (
                  <li
                    key={index}
                    data-team={node.bio.team ? node.bio.team.slug : null}
                    className={cx('relative text-center', {
                      hidden:
                        node.bio.team &&
                        selectedFilter !== node.bio.team.slug &&
                        selectedFilter !== 'all',
                    })}
                  >
                    <Link
                      to={node.uri}
                      className="mb-responsive-xl-12 block after:absolute after:inset-0 after:block after:content-['']"
                    >
                      {imageSrc && (
                        <GatsbyImage
                          image={imageSrc}
                          alt={node.bio.image.altText}
                        />
                      )}
                    </Link>

                    <p className="mb-responsive-xl-4 font-regular underline">
                      {node.bio.firstName} {node.bio.lastName}
                    </p>
                    <p className="text-sm leading-normal">{node.bio.role}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default TeamListing

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        templateName
        ... on WpTemplate_TeamListing {
          templateName
          teamPage {
            description
            teamGroupFilters {
              name
              slug
            }
            teamMembers {
              ... on WpBio {
                uri
                bio {
                  firstName
                  lastName
                  role
                  team {
                    name
                    slug
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 448
                          width: 672
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                          transformOptions: { cropFocus: NORTH }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
